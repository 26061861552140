<template>
    <div class="wraper">
        <Header :isShow="isShow" />
        <div class="banner">
            <img src="@/assets/images/join_banner.jpg" alt="" />
        </div>
        <div class="content max_big_box" ref="header" >
             <div class="title max_small_box" v-if="detail">
                <p>{{detail.name}}</p>
            </div>
            <div class="line"></div>
            <a-table class="table max_small_box" :columns="columns" :data-source="data" rowKey='id' :pagination="false" :scroll="{ y: 300 }">
            </a-table>
            <div class="detail max_small_box" v-if="detail" v-html="detail.content">
            </div>
            <!-- <div class="line"></div> -->
        </div>
    </div>
</template>

<script>
import { onUnmounted, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { get, post } from '../../utils/request';
import * as api from '../../utils/api';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import FloatingFrame from '../../components/floatingFrame/FloatingFrame';

//页面滚动处理函数
const ScrollEffect = () => {

    const isShow = ref()

    const handleScroll = (height) => {
        
        if(document.documentElement.scrollTop >= height){
            isShow.value = 1
        }else{
            isShow.value = 2
        }
    }

    return { handleScroll, isShow }
}

//详情处理模块
const detailEffect = () => {

    //详情
    const detail = ref()

    const columns = [
        {
            title: '发布时间',
            dataIndex: 'createtime',
            key: 'createtime',
        },
        {
            title: '招聘职位',
            dataIndex: 'name',
            key: 'name',
        },
        // {
        //     title: '岗位类型',
        //     dataIndex: 'type',
        //     key: 'type',
        // },
        {
            title: '所属部门',
            dataIndex: 'department',
            key: 'department',
        },
        {
            title: '学历要求',
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: '需求人数',
            dataIndex: 'hiring',
            key: 'hiring',
        },
        {
            title: '工资待遇',
            dataIndex: 'wages',
            key: 'wages',
        },
        {
            title: '性别',
            dataIndex: 'sex',
            key: 'sex',
        },
    ];

    const data = ref()

     //获取详情
    const getDetail = async (id) => {
        let postData = {
            id: id,
        }
        try{
            const result = await post('/index/get_recruitment_detail', postData)
            // console.log('加入我们详情', result)
            let arr = []
            detail.value = result.data
            arr.push(result.data)
            data.value = arr
        }catch(e) {
        
        }
    } 

    return { detail, getDetail, columns, data }
}

export default {
    name: 'JoinDetail',
    components: { Header, Footer, FloatingFrame },
    setup() {
        const route = useRoute()
        //页面滚动处理函数
        const { 
            handleScroll, 
            isShow 
        } = ScrollEffect()

        //详情处理模块
        const { detail, getDetail, columns, data } = detailEffect()

        //获取滑块到顶部的距离
        const header = ref(null)

        onMounted(() => {
            document.documentElement.scrollTop = document.body.scrollTop =0;
            window.addEventListener('scroll', function () {
                if(header.value){
                    handleScroll(header.value.offsetTop);
                }
            })
        });

        getDetail(route.params.id)  //获取详情

        onUnmounted(() => {
            document.querySelector('body').setAttribute('style', 'background-color:#fff')
        })

        const { imgUrl }  = api

        return { imgUrl, isShow, header, detail, columns, data }
    }
}
</script>

<style lang="scss" scoped>
@import '../../style/viriables.scss';
/* 轮播图 */
.banner{
    margin-top: 111px;
    @media screen and (max-width: 1200px){
        margin-top: $phone-banner-marginTop;
        height: $phone-banner-height;
    }
    img{
        width: 100%;
        display: block;
        @media screen and (max-width: 1200px){
            height: 100%;
            object-fit: cover;
        }
    }
}
.content{
    margin-top: 50px;
    .title{
        text-align: center;
        @media screen and (max-width: 1200px){
            padding: 0 30px;
        }
        p{
            color: rgba(16, 16, 16, 100);
            font-size: 20px;
            font-weight: bold;
        }
    }
    .line{
        width: 100%;
        height: 1px;
        background: rgba(187, 187, 187, 100);
        margin-top: 30px;
    }
    .table{
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .detail{
        margin-top: 30px;
        line-height: 30px;
        @media screen and (max-width: 1200px){
            margin: 30px 30px 0 30px;
            padding: 0 30px;
        }
    }
}

</style>